<template>
  <v-container
      id="projects"
      fluid
      align="center"
  >

    <base-material-card
        icon="mdi-credit-card"
        inline
        title="Add New Card"
    >
      <v-card-text class="pa-0 ma-0">
        <div v-if="canAccess({module: 'saas', method: 'get', route: '/paymentMethod'})" class="float-right">
          <v-btn small color="warning lighten-2" @click="$router.push({name: 'Payment'})">
            <v-icon class="mr-2">mdi-arrow-left-circle</v-icon>
            Back
          </v-btn>
        </div>
        <br />
        <br />
        <v-container fluid>
          <v-row>
            <v-col md="6" sm="12" xs="12">
              <br/>
              <h4 class="mb-4 ml-0">Card Information</h4>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      color="secondary"
                      label="Name on Card"
                      v-model="newCard.cardName"
                      dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      color="secondary"
                      label="Card Number"
                      @keyup="onlyDigit"
                      maxlength="19"
                      v-model="newCard.cardNumber"
                      dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-select
                      :items="['01','02','03','04','05','06','07','08','09','10','11', '12']"
                      label="Month"
                      v-model="newCard.cardMonth"
                      dense>
                  </v-select>
                </v-col>
                <v-col cols="4">
                  <v-select
                      :items="cardAllowedYears"
                      label="Year"
                      v-model="newCard.cardYear"
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                      color="secondary"
                      label="CVV"
                      type="password"
                      maxlength="4"
                      v-model="newCard.cardCvv"
                      dense
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <h4 class="mb-4 ml-0">Billing Information</h4>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          type="email"
                          color="secondary"
                          label="Email"
                          v-model="billingInfo.email"
                          dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          type="phone"
                          color="secondary"
                          label="Phone"
                          v-model="billingInfo.phone"
                          dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                          color="secondary"
                          label="Street"
                          v-model="billingInfo.street"
                          dense
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                          color="secondary"
                          label="City"
                          v-model="billingInfo.city"
                          dense
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                          color="secondary"
                          label="State"
                          v-model="billingInfo.state"
                          dense
                      />
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                          color="secondary"
                          label="Postal Code"
                          v-model="billingInfo.postal_code"
                          dense
                      />
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12">
                      <div class="v-input__slot country-select">
                        <div class="v-select__slot">
                          <country-select class="v-label theme--light"
                                          color="secondary" placeholder="Country"
                                          v-model="billingInfo.country" :country="billingInfo.country"/>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>
              </v-row>
            </v-col>
            <v-col md="6" sm="12" xs="12" class="align-center text-center">
              <br/>
              <v-spacer></v-spacer>
              <vue-paycard :value-fields="newCard"/>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            v-if="canAccess({module: 'saas', method: 'post', route: '/paymentMethod'})"
            right
            color="secondary"
            @click="checkForm"
        >
          <v-icon class="mr-1">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn
            right
            color="error"
            @click="resetForm"
        >
          <v-icon class="mr-1">mdi-cancel</v-icon>
          Reset
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import {VuePaycard} from 'vue-paycard'

const data = {
  loading: true,
  cardAllowedYears: [],
  billingInfo: {},
  newCard: {
    cardName: '',
    cardNumber: '',
    cardMonth: '',
    cardYear: '',
    cardCvv: ''
  }
};

const methods = {

  resetForm() {
    this.billingInfo = {};
    this.newCard = {
      cardName: '',
      cardNumber: '',
      cardMonth: '',
      cardYear: '',
      cardCvv: ''
    };
  },

  checkForm() {
    const self = this;
    if (!this.newCard.cardName || this.newCard.cardName.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Credit Card Error',
        text: 'Please enter the name on the card!'
      });
      return false;
    }

    if (!this.newCard.cardNumber || this.newCard.cardNumber.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Credit Card Error',
        text: 'Please enter the card number!'
      });
      return false;
    }

    if (!this.newCard.cardMonth || this.newCard.cardMonth === '') {
      self.pushMessage({
        type: 'error',
        title: 'Credit Card Error',
        text: 'Please enter the card expiry month!'
      });
      return false;
    }

    if (!this.newCard.cardYear || this.newCard.cardYear === '') {
      self.pushMessage({
        type: 'error',
        title: 'Credit Card Error',
        text: 'Please enter the card expiry year!'
      });
      return false;
    }

    if (!this.newCard.cardCvv || this.newCard.cardCvv === '') {
      self.pushMessage({
        type: 'error',
        title: 'Credit Card Error',
        text: 'Please enter the card CVV!'
      });
      return false;
    }

    //billing

    if (!this.billingInfo.email || this.billingInfo.email.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter the your email!'
      });
      return false;
    }

    if (!this.billingInfo.phone || this.billingInfo.phone.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter the your phone number!'
      });
      return false;
    }

    if (!this.billingInfo.street || this.billingInfo.street.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing street address!'
      });
      return false;
    }

    if (!this.billingInfo.city || this.billingInfo.city.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing city name!'
      });
      return false;
    }

    if (!this.billingInfo.state || this.billingInfo.state.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing state name!'
      });
      return false;
    }

    if (!this.billingInfo.postal_code || this.billingInfo.postal_code.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing postal_code!'
      });
      return false;
    }

    if (!this.billingInfo.country || this.billingInfo.country.trim() === '') {
      self.pushMessage({
        type: 'error',
        title: 'Billing Information Error',
        text: 'Please enter your billing country!'
      });
      return false;
    }

    //todo: this should be enhanced at later stages to use country from DDM
    // and ask for postal code optionally
    this.saveCard();
  },

  saveCard() {
    const self = this;
    self.getSendData({
      url: `/saas/paymentMethod`,
      method: 'post',
      params: {
        "billing": {
          "name": self.newCard.cardName,
          "email": self.billingInfo.email,
          "phone": self.billingInfo.phone,
          "card": {
            "number": self.newCard.cardNumber,
            "exp_month": self.newCard.cardMonth,
            "exp_year": self.newCard.cardYear,
            "cvc": self.newCard.cardCvv
          },
          "address": {
            "country": self.billingInfo.country,
            "city": self.billingInfo.city,
            "state": self.billingInfo.state,
            "line1": self.billingInfo.street,
            "postal_code": self.billingInfo.postal_code
          }
        }
      }
    }).then(response => {
      self.pushMessage({
        type: 'success',
        title: 'Credit Card',
        text: 'Credit Card Saved Successfully'
      });
      setTimeout(() => {
        self.resetForm();
        self.$router.push({name: 'Payment'});
      }, 500);
    });
  },

  onlyDigit() {
    let re = /^[0-9]$/
    if (!re.test(this.newCard.cardNumber)) {
      let str = this.newCard.cardNumber.replace(/[^\d\s]+/g, '');
      this.newCard.cardNumber = str;
    }
  },

  async initialize() {
    const self = this;
    setTimeout(() => {
      self.loading = false;
    }, 500);
  }

};

export default {
  name: 'addPaymentMethods',
  methods: methods,
  props: {},
  components: {
    VuePaycard
  },

  computed: {},

  mixins: [globalMixins],

  watch: {
    "newCard.cardNumber": function (to, from) {
      if ([4, 9, 14].includes(to.length)) {
        this.newCard.cardNumber += ' ';
      }
    }
  },

  async created() {

    let thisYear = new Date().getFullYear();
    for (let i = thisYear; i <= thisYear + 10; i++) {
      this.cardAllowedYears.push(i);
    }

    this.clearMessages();
    setTimeout(() => {
      this.initialize();
    }, 500);
  },

  data: () => (data),
}
</script>
<style>

select {

}

.country-select .v-select__slot {
  padding: 6px 0 4px;
  border-bottom: 1px solid;
  border-color: rgba(0, 0, 0, 0.3);
}

.country-select select option {
  padding-top: 10px;
  padding-bottom: 5px;
  color: black;
}

</style>